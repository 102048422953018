<template>
	<div class="dataanalysis">
		<div class="my-title">
			<p>财务数据分析</p>
		</div>
		<div style="margin: 0 20px; padding-bottom: 30px">
			<div class="sy-card-header" style="position: relative">
				<i></i>财务科目数据
			</div>
			<div style="display: flex">
				<div style="margin-right: 10px">
					<el-form :model="ruleForm" status-icon ref="ruleForm" class="demo-ruleForm" label-width="90px">
						<div class="search-list" style="display: flex">
							<el-form-item id="v-step-11" label="客户名称:" :rules="[
						{
							required: true,
							message: '请输入客户名称',
						},
					]" prop="clientName">
								<el-autocomplete clearable suffix-icon="el-icon-arrow-down" style="width: 100%" class="inline-input"
									v-model="ruleForm.clientName" :fetch-suggestions="querySearch" placeholder="请输入客户名称"
									@input="setValueNull"></el-autocomplete>
							</el-form-item>
						</div>
					</el-form>
				</div>
				<div style="padding-top: 2px">
					<el-button id="v-step-22" @click="submitForm('ruleForm', 'diyImportVisible')" type="primary">
						导入
					</el-button>
					<el-dropdown @command="handleCommand" style="margin: 0 10px" placement="bottom-start">
						<el-button id="v-step-01" type="primary">
							下载导入模板<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(
									item, index
								) in FinanceSubjectTemplateType" :key="index" :command="item">{{ item.label }}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-button type="primary" @click="submitForm('ruleForm', 'subjectsVisible')">
						新增
					</el-button>
					<el-button id="v-step-33" type="primary" @click="getfList">
						自动计算财务指标数据
					</el-button>
					<el-button type="primary" @click="clearData">
						清空财务科目和指标数据
					</el-button>
					<download-excel v-if="false" class="export-excel-wrapper" :data="SubjectList" :fields="jsonFields"
						name="财务科目">
						<el-button type="primary"> 导出 </el-button>
					</download-excel>
					<el-button @click="exportEven('fmFinanceSubject')" type="primary">
						导出
					</el-button>
				</div>
			</div>
			<div>
				<el-table max-height="400" :data="SubjectList" border>
					<el-table-column align="left" label="财务日期" width="100">
						<template slot-scope="scope">
							{{ scope.row.financeDate.slice(0, 7) }}
						</template>
					</el-table-column>
					<el-table-column align="left" prop="assetsCurrent" label="流动资产(元)" width="120">
					</el-table-column>
					<el-table-column align="left" prop="assetsTotal" label="总资产(元)" width="120">
					</el-table-column>
					<el-table-column align="left" prop="payableCurrent" label="流动负债(元)" width="120">
					</el-table-column>
					<el-table-column align="left" prop="payableTotal" label="总负债(元)" width="120">
					</el-table-column>
					<el-table-column align="left" prop="ownerEquity" label="所有者权益(元)" width="150">
					</el-table-column>
					<el-table-column align="left" prop="businessIncome" label="主营业务收入(元)" width="150">
					</el-table-column>
					<el-table-column align="left" prop="businessCost" label="主营业务成本(元)" width="150">
					</el-table-column>
					<el-table-column align="left" prop="businessProfit" label="营业利润(元)" width="120">
					</el-table-column>
					<el-table-column align="left" prop="netProfit" label="净利润(元)" width="120">
					</el-table-column>

					<el-table-column width="180" fixed="right" align="center" label="操作">
						<template slot-scope="scope">
							<span style="
									color: rgb(2, 167, 240);
									cursor: pointer;
									margin-right: 17px;
								" @click="readRowData(scope.row.subjectId)">
								查看
							</span>
							<span style="
									color: rgb(2, 167, 240);
									cursor: pointer;
									margin-right: 17px;
								" @click="changeRowData(scope.row.subjectId)">
								修改
							</span>
							<span style="
									color: rgb(2, 167, 240);
									cursor: pointer;
									margin-right: 17px;
								" @click="delRowData(scope.row.subjectId)">
								删除
							</span>
						</template>
					</el-table-column>
					<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
				</el-table>
			</div>
			<div class="sy-card-header" style="position: relative; margin: 20px 0">
				<i></i>财务指标数据

				<el-button type="primary" @click="submitForm('ruleForm', 'indicatorVisible')">
					新增
				</el-button>
				<download-excel v-if="false" class="export-excel-wrapper" :data="calculateList" :fields="calculateFields"
					name="财务指标">
					<el-button type="primary"> 导出 </el-button>
				</download-excel>
				<el-button type="primary" @click="exportEven('fmFinanceQuota')">
					导出
				</el-button>
			</div>
			<div>
				<el-table max-height="400" :data="calculateList" border>
					<el-table-column align="left" label="财务日期" width="100">
						<template slot-scope="scope">
							{{ scope.row.financeDate.slice(0, 7) }}
						</template>
					</el-table-column>
					<el-table-column align="left" prop="ratioCurrent" width="120" label="流动比率(%)">
					</el-table-column>
					<el-table-column align="left" prop="ratioQuick" label="速动比率(%)" width="120">
					</el-table-column>
					<el-table-column align="left" prop="liabRateAssets" width="120" label="资产负债率(%)">
					</el-table-column>
					<el-table-column align="left" prop="liabRateBank" label="银行负债率(%)" width="120">
					</el-table-column>
					<el-table-column align="left" prop="receivablesTurnover" label="应收账款周转天数" width="150">
					</el-table-column>
					<el-table-column align="left" prop="inventoryTurnover" width="120" label="存货周转天数">
					</el-table-column>
					<el-table-column align="left" prop="saleGrossMargin" width="120" label="销售毛利率(%)">
					</el-table-column>
					<el-table-column align="left" prop="saleInterestRate" width="120" label="销售净利率(%)">
					</el-table-column>
					<el-table-column align="left" prop="growthRateRevenue" label="销售收入增长率(%)" width="150">
					</el-table-column>
					<el-table-column align="left" prop="growthRateProfit" label="净利润增长率(%)" width="150">
					</el-table-column>
					<el-table-column align="left" prop="cashRatio" label="现金比率(%)" width="150">
					</el-table-column>
					<el-table-column width="180" fixed="right" align="center" label="操作">
						<template slot-scope="scope">
							<span style="
									color: rgb(2, 167, 240);
									cursor: pointer;
									margin-right: 17px;
								" @click="addReadRowData(scope.row.quotaId)">
								查看
							</span>
							<span style="
									color: rgb(2, 167, 240);
									cursor: pointer;
									margin-right: 17px;
								" @click="addChenageRowData(scope.row.quotaId)">
								修改
							</span>
							<span style="
									color: rgb(2, 167, 240);
									cursor: pointer;
									margin-right: 17px;
								" @click="addDelRowData(scope.row.quotaId)">
								删除
							</span>
						</template>
					</el-table-column>
					<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
				</el-table>
			</div>
		</div>
		<subjectsDrawer @closeVisible="() => {
						subjectsVisible = false;
						getfmFinanceSubjectList();
						getUserNameList();
					}
						" :subjectsVisible.sync="subjectsVisible" :parentRuleForm="ruleForm" :subjectState="subjectState"
			:subjectData="subjectData" />
		<indicatorDrawer :indicatorData="indicatorData" :indicatorState="indicatorState" @closeVisible="() => {
						indicatorVisible = false;
						getfmFinanceQuota();
					}
						" :indicatorVisible.sync="indicatorVisible" :clientName="ruleForm.clientName" />
		<diyImportDrawer @closeVisible="() => {
						diyImportVisible = false;
						getfmFinanceSubjectList();
						getUserNameList();
					}
						" :diyImportVisible.sync="diyImportVisible" :clientName="ruleForm.clientName"
			:FinanceSubjectTemplateType="FinanceSubjectTemplateType" />
		<v-tour :options="myOptions" name="myTour1" :steps="steps"></v-tour>
	</div>
</template>

<script>
import subjectsDrawer from './model/subjectsDrawer.vue';
import indicatorDrawer from './model/indicatorDrawer.vue';
import diyImportDrawer from './model/diyImportDrawer.vue';
import {
	fmFinanceSubjectList,
	fmFinanceSubjectgetUserNameList,
	calculateFinanceQuota,
	fmFinanceQuota,
	fmFinanceSubjectRead,
	fmFinanceSubjectDelete,
	fmFinanceQuotaRead,
	fmFinanceQuotaDelete,
	itemByType,
	clear,
} from '@/api/index.js';

export default {
	name: 'dataanalysis',
	data() {
		return {
			FinanceSubjectTemplateType: [],

			jsonFields: {
				'财务日期 ': 'financeDate',
				'流动资产(元)': 'assetsCurrent',
				'总资产(元)': 'assetsTotal',
				'流动负债(元)': 'payableCurrent',
				'总负债(元)': 'payableTotal',
				'所有者权益(元)': 'ownerEquity',
				'主营业务收入(元)': 'businessIncome',
				'主营业务成本(元)': 'businessCost',
				'营业利润(元)': 'businessProfit',
				'净利润(元)': 'netProfit',
				'纳税申报收入(元)': 'taxesIncome',
				'纳税申报利润(元)': 'taxesProfit',
			},
			calculateFields: {
				'财务日期 ': 'financeDate',
				'流动比率(%)': 'ratioCurrent',
				'速动比率(%)': 'ratioQuick',
				'资产负债率(%)': 'liabRateAssets',
				'银行负债率(%)': 'liabRateBank',
				'应收账款周转天数 ': 'receivablesTurnover',
				'存货周转天数 ': 'inventoryTurnover',
				'销售毛利率(%)': 'saleGrossMargin',
				'销售净利率(%)': 'saleInterestRate',
				'销售收入增长率(%)': 'growthRateRevenue',
				'净利润增长率(%)': 'growthRateProfit',
				'现金比率(%)': 'cashRatio',
			},
			subjectsVisible: false,
			indicatorVisible: false,
			diyImportVisible: false,
			currentPage: 1,
			ProductCategory: [],
			ruleForm: {
				clientName: '',
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
			},
			restaurants: [],
			calculateList: [],
			SubjectList: [],
			subjectState: '',
			subjectData: {},
			indicatorState: '',
			indicatorData: {},
			steps: [
				{
					target: '#v-step-01',
					content: `下载所需的数据模板`,
				},
				{
					target: '#v-step-11',
					content: '补充对应的客户名称',
				},
				{
					target: '#v-step-22',
					content: '点击导入按钮',
				},
				{
					target: '#v-step-33',
					content:
						'导入成功后,点击自动计算财务指标数据，就可以得到财务指标数据',
					params: {
						placement: 'top',
					},
				},
			],
			myOptions: {
				useKeyboardNavigation: false, //不使用←、→和ESC键来导航tour
				startTimeout: 1000, //1秒后执行
				labels: {
					buttonSkip: '跳过',
					buttonPrevious: '上一步',
					buttonNext: '下一步',
					buttonStop: '关闭',
				},
			},
		};
	},
	created() {
		this.getItemByType();
	},
	mounted() {
		this.getUserNameList();
		this.$nextTick(() => {
			if (!localStorage.getItem('D')) {
				localStorage.setItem('D', 1);
				this.$tours['myTour1'].start();
			}
		});
	},
	methods: {
		clearData() {
			this.$confirm('确定清空当前客户财务指标数据？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(async () => {
					let obj = {};
					if (this.ruleForm.clientName) {
						obj.clientName = this.ruleForm.clientName;
					}
					await clear(obj);
					this.restaurants = [];
					this.SubjectList = [];
					this.calculateList = [];
					this.ruleForm.clientName = '';
					this.getUserNameList();
				})
				.catch(() => { });
		},
		async getItemByType() {
			const res = await itemByType({
				dictionaryType: 'FinanceSubjectTemplateType',
			});
			this.FinanceSubjectTemplateType = res.data.map((item) => ({
				value: item.itemValue,
				label: item.itemName,
			}));
		},
		handleCommand(val) {
			location.href =
				this.$baseUrl +
				`api/fmFinanceSubject/downloadFinanceSubjectTemplate.do?templateType=${val.value}&fileName=${val.label}`;
		},
		setValueNull() {
			this.$Debounce(this.getTableListData);
		},
		getTableListData() {
			this.getfmFinanceSubjectList();
			this.getfmFinanceQuota();
		},

		submitForm(formName, name) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.indicatorState = '新增';
					this.subjectState = '新增';
					this[name] = true;
				} else {
					this.$confirm('请输入客户名称', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
					});
					console.log('error submit!!');
					return false;
				}
			});
		},
		exportEven(url) {
			this.$refs['ruleForm'].validate((valid) => {
				if (valid) {
					location.href =
						this.$baseUrl +
						`api/${url}/export.do?clientName=${this.ruleForm.clientName}&cooperationPresidentId=${this.$store.state.userInfo.userVo.cooperationPresidentId}&systematic=2`;
				}
			});
		},
		async getUserNameList() {
			const res = await fmFinanceSubjectgetUserNameList({
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
			});
			this.restaurants = res.rtnDataList.map((item) => ({ value: item }));
		},
		querySearch(queryString, cb) {
			cb(this.restaurants);
		},
		async getfmFinanceSubjectList() {
			const res = await fmFinanceSubjectList({
				...this.ruleForm,
				pageNo: 1,
				pageSize: 999,
			});
			this.SubjectList = res.list;
		},
		async getfmFinanceQuota() {
			const res = await fmFinanceQuota({
				...this.ruleForm,
				pageNo: 1,
				pageSize: 999,
			});
			this.calculateList = res.list;
		},
		async getfList() {
			if (this.SubjectList.length > 0) {
				await calculateFinanceQuota({ ...this.ruleForm });
				this.getfmFinanceQuota();
				this.$confirm('计算财务指标数据成功！', '提示', {
					confirmButtonText: '确定',
					type: 'success',
					showCancelButton: false,
				});
				// if (!res.returncode) {
				// 	// this.calculateObj = res;
				// 	// this.calculateObj.cycleAverageDailyBalanceRate =
				// 	// 	res.cycleAverageDailyBalanceRate ||
				// 	// 	'上一年度主营业务收入未录入，无法计算得到对应的值';
				// 	this.$confirm('', '提示', {
				// 		confirmButtonText: '确定',
				// 		type: 'success',
				// 		showCancelButton: false,
				// 	});
				// }
			} else {
				this.$message({
					message: '暂无数据，无法计算',
					type: 'error',
				});
			}
		},
		async readRowData(id, state = false) {
			this.subjectState = '查看';

			const res = await fmFinanceSubjectRead({ subjectId: id });
			this.subjectData = res.data;
			if (!state) {
				this.subjectsVisible = true;
			}
		},
		async changeRowData(id) {
			await this.readRowData(id, true);
			this.subjectState = '编辑';
			this.subjectsVisible = true;
		},
		async delRowData(id) {
			const res = await fmFinanceSubjectDelete({ subjectId: id });
			if (res.returncode == 0) {
				this.$message({
					message: '删除成功',
					type: 'success',
				});
				this.getfmFinanceSubjectList();
			}
		},
		async addReadRowData(id, state = false) {
			this.indicatorState = '查看';

			const res = await fmFinanceQuotaRead({ quotaId: id });
			this.indicatorData = res.data;
			if (!state) {
				this.indicatorVisible = true;
			}
		},
		async addChenageRowData(id) {
			await this.addReadRowData(id, true);
			this.indicatorState = '编辑';
			this.indicatorVisible = true;
		},
		async addDelRowData(id) {
			const res = await fmFinanceQuotaDelete({ quotaId: id });
			if (res.returncode == 0) {
				this.$message({
					message: '删除成功',
					type: 'success',
				});
				this.getfmFinanceQuota();
			}
		},
	},
	components: {
		subjectsDrawer,
		indicatorDrawer,
		diyImportDrawer,
	},
};
</script>
<style lang="scss" scoped>
.dataanalysis {
	width: 100%;
	height: calc(100vh - 150px);
	overflow: auto;
}

.fy {
	text-align: end;
	padding: 10px 20px;
}

.sy-card-header {
	height: 42px;
	line-height: 42px;
	border-bottom: 1px solid #f6f6f6;
	font-size: 14px;
	font-weight: bold;
}

.sy-card-header i {
	width: 3px;
	height: 14px;
	background: #5799f0;
	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
	margin-right: 5px;
}

.file-list {
	color: #009688;
	position: relative;
	border: 1px solid #e6e6e6;
	box-sizing: border-box;
	padding: 10px;
	margin: 10px 0;

	>p {
		position: absolute;
		top: -7px;
		font-size: 12px;
		left: 16px;
		background: #fff;
		padding: 0 5px;
	}
}

.content-box {
	padding: 0 10px;
	display: flex;

	div {
		flex: 1;
	}

	p {
		color: #888;
		margin: 20px 10px;
	}
}

::v-deep .el-tabs__header {
	// margin: 0;
}

.mb {
	margin-bottom: 10px;
}

.el-icon-circle-plus-outline {
	color: #409eff;
}

.el-icon-remove-outline {
	color: red;
}

.search-list>div {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	width: 100%;
}

::v-deep .add-list>div {
	width: 100%;
	box-sizing: border-box;
}

.add-list {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

::v-deep .add-list label {
	opacity: 0;
}

::v-deep .add-list>div:first-child label {
	opacity: 1;
}

::v-deep .sy-card-header {
	border-bottom: none;
}

::v-deep .search-list .el-form-item>div {
	margin-left: 0 !important;
	flex: 1;
}

.export-excel-wrapper {
	display: inline-block;
	margin-left: 10px;
}
</style>
