<template>
	<el-drawer :close-on-press-escape="false" :wrapperClosable="false" :title="indicatorState + '财务指标'"
		:visible.sync="drawer_" direction="rtl" custom-class="demo-drawer" ref="drawer" size="70%" @open="open" @close="() => {
			Object.assign($data, $options.data());

			$refs['inRuleForm'].resetFields();
			$emit('closeVisible');
		}
		">
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-form :model="ruleForm" status-icon ref="inRuleForm" class="demo-ruleForm" label-position="rigth"
					label-width="170px" v-if="indicatorState != '查看'">
					<el-form-item label="财务日期:" :rules="[
		{
			required: true,
			message: '财务日期不能为空',
		},
	]" prop="financeDate">
						<el-date-picker type="month" placeholder="选择日期" format="yyyy-MM" value-format="yyyy-MM" style="width: 100%"
							v-model="ruleForm.financeDate">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="流动比率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入流动比率(%)" v-model="ruleForm.ratioCurrent"></el-input>
					</el-form-item>
					<el-form-item label="速动比率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入速动比率(%)" v-model="ruleForm.ratioQuick"></el-input>
					</el-form-item>
					<el-form-item label="资产负债率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入资产负债率(%)" v-model="ruleForm.liabRateAssets"></el-input>
					</el-form-item>
					<el-form-item label="银行负债率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入银行负债率(%)" v-model="ruleForm.liabRateBank"></el-input>
					</el-form-item>
					<el-form-item label="应收账款周转天数:">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入应收账款周转天数" v-model="ruleForm.receivablesTurnover"></el-input>
					</el-form-item>
					<el-form-item label="存货周转天数:">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入存货周转天数" v-model="ruleForm.inventoryTurnover"></el-input>
					</el-form-item>
					<el-form-item label="销售毛利率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入销售毛利率(%)" v-model="ruleForm.saleGrossMargin"></el-input>
					</el-form-item>
					<el-form-item label="销售净利率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入销售净利率(%)" v-model="ruleForm.saleInterestRate"></el-input>
					</el-form-item>
					<el-form-item label="销售收入增长率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入销售收入增长率(%)" v-model="ruleForm.growthRateRevenue"></el-input>
					</el-form-item>
					<el-form-item label="净利润增长率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入净利润增长率(%)" v-model="ruleForm.growthRateProfit"></el-input>
					</el-form-item>
					<el-form-item label="现金比率(%):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入现金比率(%)" v-model="ruleForm.cashRatio"></el-input>
					</el-form-item>
					<el-form-item label="财务管理规范性:">
						<el-select style="width: 100%" v-model="ruleForm.financeNormative" placeholder="请选择">
							<el-option v-for="item in FinanceNormative" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="审计师事务所资质:">
						<el-select style="width: 100%" v-model="ruleForm.auditorOfficeQualification" placeholder="请选择">
							<el-option v-for="item in AuditorOfficeQualification" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<el-form :model="ruleForm" status-icon class="demo-ruleForm" label-position="rigth" label-width="170px" v-else>
					<el-form-item label="财务日期:">
						{{ ruleForm.financeDate.slice(0, 7) }}
					</el-form-item>
					<el-form-item label="流动比率(%):">
						{{ ruleForm.ratioCurrent }}
					</el-form-item>
					<el-form-item label="速动比率(%):">
						{{ ruleForm.ratioQuick }}
					</el-form-item>
					<el-form-item label="资产负债率(%):">
						{{ ruleForm.liabRateAssets }}
					</el-form-item>
					<el-form-item label="银行负债率(%):">
						{{ ruleForm.liabRateBank }}
					</el-form-item>
					<el-form-item label="应收账款周转天数:">
						{{ ruleForm.receivablesTurnover }}
					</el-form-item>
					<el-form-item label="存货周转天数:">
						{{ ruleForm.inventoryTurnover }}
					</el-form-item>
					<el-form-item label="销售毛利率(%):">
						{{ ruleForm.saleGrossMargin }}
					</el-form-item>
					<el-form-item label="销售净利率(%):">
						{{ ruleForm.saleInterestRate }}
					</el-form-item>
					<el-form-item label="销售收入增长率(%):">
						{{ ruleForm.growthRateRevenue }}
					</el-form-item>
					<el-form-item label="净利润增长率(%):">
						{{ ruleForm.growthRateProfit }}
					</el-form-item>
					<el-form-item label="现金比率(%):">
						{{ ruleForm.cashRatio }}
					</el-form-item>
					<el-form-item label="财务管理规范性:">
						{{ ruleForm.financeNormativeName }}
					</el-form-item>
					<el-form-item label="审计师事务所资质:">
						{{ ruleForm.auditorOfficeQualificationName }}
					</el-form-item>
				</el-form>
				<div class="demo-drawer__footer">
					<el-button v-if="indicatorState != '查看'" type="primary" @click="add">保存</el-button>

					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import {
	fmFinanceQuotaAdd,
	itemByType,
	fmFinanceQuotaUpdate,
} from '@/api/index.js';
import moment from 'moment';

export default {
	name: 'indicatorDrawer',
	props: {
		indicatorVisible: {
			type: Boolean,
			required: true,
		},
		clientName: {
			type: String,
			required: true,
		},
		indicatorState: {
			type: String,
		},
		indicatorData: {
			type: Object,
		},
	},
	data() {
		return {
			ruleForm: {
				financeDate: '',
				ratioCurrent: '',
				ratioQuick: '',
				liabRateAssets: '',
				liabRateBank: '',
				receivablesTurnover: '',
				inventoryTurnover: '',
				saleGrossMargin: '',
				saleInterestRate: '',
				growthRateRevenue: '',
				growthRateProfit: '',
				cashRatio: '',
				financeNormative: '',
				auditorOfficeQualification: '',
				cooperationPresidentId: '',
				clientName: '',
			},
			FinanceNormative: [],
			AuditorOfficeQualification: [],
		};
	},
	methods: {
		add() {
			this.$refs['inRuleForm'].validate(async (valid) => {
				if (valid) {
					let res;
					this.ruleForm.financeDate = moment(
						this.ruleForm.financeDate
					)
						.endOf('month')
						.format('YYYY-MM-DD');
					this.ruleForm.cooperationPresidentId =
						this.$store.state.userInfo.userVo.cooperationPresidentId;
					this.ruleForm.clientName = this.clientName;
					if (this.indicatorState == '新增') {
						res = await fmFinanceQuotaAdd(this.ruleForm);
					} else if (this.indicatorState == '编辑') {
						res = await fmFinanceQuotaUpdate(this.ruleForm);
					}
					if (res.returncode == 0) {
						this.$confirm('提交成功', '提示', {
							confirmButtonText: '确定',
							type: 'success',
							showCancelButton: false,
						});
						this.$emit('closeVisible');
					}
				}
			});
		},
		open() {
			this.getItemByType('FinanceNormative');
			this.getItemByType('AuditorOfficeQualification');
			if (this.indicatorState == '查看') {
				this.ruleForm = JSON.parse(JSON.stringify(this.indicatorData));
			} else if (this.indicatorState == '编辑') {
				this.ruleForm = JSON.parse(JSON.stringify(this.indicatorData));
			}
		},
		async getItemByType(name) {
			const res = await itemByType({
				dictionaryType: name,
			});
			this[name] = res.data.map((item) => ({
				value: String(item.itemValue),
				label: item.itemName,
			}));
		},
	},
	created() { },
	computed: {
		drawer_: {
			get() {
				return this.indicatorVisible;
			},
			set(v) {
				this.$emit('update:indicatorVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .demo-ruleForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

::v-deep .demo-ruleForm>div {
	width: 48%;
}

::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;

	.el-button {
		width: 15%;
	}
}
</style>
