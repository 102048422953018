<template>
	<el-drawer :close-on-press-escape="false" :wrapperClosable="false" :title="subjectState + '财务科目'"
		:visible.sync="drawer_" direction="rtl" custom-class="demo-drawer" ref="drawer" size="70%" @open="open" @close="() => {
		ruleForm = $options.data().ruleForm;

		$refs['subRuleForm'].resetFields();
		$emit('closeVisible');
	}
		">
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-form :model="ruleForm" status-icon ref="subRuleForm" class="demo-ruleForm" label-position="rigth"
					label-width="170px" v-if="subjectState != '查看'">
					<el-form-item label="财务日期:" :rules="[
		{
			required: true,
			message: '财务日期不能为空',
		},
	]" prop="financeDate">
						<el-date-picker type="month" placeholder="选择日期" format="yyyy-MM" value-format="yyyy-MM" style="width: 100%"
							v-model="ruleForm.financeDate">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="货币资金(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入货币资金(元)	" v-model="ruleForm.monetaryCapital"></el-input>
					</el-form-item>
					<el-form-item label="应收票据(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入应收票据(元)	" v-model="ruleForm.receivableNote"></el-input>
					</el-form-item>
					<el-form-item label="应收账款(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入应收账款(元)	" v-model="ruleForm.receivableAccount"></el-input>
					</el-form-item>
					<el-form-item label="存货(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入存货(元)	" v-model="ruleForm.stockAmount"></el-input>
					</el-form-item>
					<el-form-item label="其他应收款(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入其他应收款(元)	" v-model="ruleForm.receivableOther"></el-input>
					</el-form-item>
					<el-form-item label="流动资产(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入流动资产(元)	" v-model="ruleForm.assetsCurrent"></el-input>
					</el-form-item>
					<el-form-item label="无形资产(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入无形资产(元)	" v-model="ruleForm.assetsIntangible"></el-input>
					</el-form-item>
					<el-form-item label="固定资产(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入固定资产(元)	" v-model="ruleForm.assetsFixed"></el-input>
					</el-form-item>
					<el-form-item label="总资产(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入总资产(元)	" v-model="ruleForm.assetsTotal"></el-input>
					</el-form-item>
					<el-form-item label="短期借款(元):">
						<el-input placeholder="请输入短期借款(元)	"
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							v-model="ruleForm.shortLoan"></el-input>
					</el-form-item>
					<el-form-item label="应付票据(元):">
						<el-input placeholder="请输入应付票据(元)	"
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							v-model="ruleForm.payableNote"></el-input>
					</el-form-item>
					<el-form-item label="应付账款(元):">
						<el-input placeholder="请输入应付账款(元)	"
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							v-model="ruleForm.payableAccount"></el-input>
					</el-form-item>
					<el-form-item label="其他应付款(元):">
						<el-input placeholder="请输入其他应付款(元)	"
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							v-model="ruleForm.payableOther"></el-input>
					</el-form-item>
					<el-form-item label="流动负债(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入流动负债(元)	" v-model="ruleForm.payableCurrent"></el-input>
					</el-form-item>
					<el-form-item label="长期借款(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入长期借款(元)	" v-model="ruleForm.longLoan"></el-input>
					</el-form-item>
					<el-form-item label="总负债(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入总负债(元)	" v-model="ruleForm.payableTotal"></el-input>
					</el-form-item>
					<el-form-item label="实收资本(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入实收资本(元)	" v-model="ruleForm.capitalPaid"></el-input>
					</el-form-item>
					<el-form-item label="资本公积(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入资本公积(元)	" v-model="ruleForm.capitalReserve"></el-input>
					</el-form-item>
					<el-form-item label="未分配利润(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入未分配利润(元)	" v-model="ruleForm.undistributedProfit"></el-input>
					</el-form-item>
					<el-form-item label="所有者权益(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入所有者权益(元)	" v-model="ruleForm.ownerEquity"></el-input>
					</el-form-item>
					<el-form-item label="主营业务收入(元):">
						<el-input placeholder="请输入主营业务收入(元)	"
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							v-model="ruleForm.businessIncome"></el-input>
					</el-form-item>
					<el-form-item label="主营业务成本(元):">
						<el-input placeholder="请输入主营业务成本(元)	"
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							v-model="ruleForm.businessCost"></el-input>
					</el-form-item>
					<el-form-item label="营业利润(元):">
						<el-input placeholder="请输入营业利润(元)	"
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							v-model="ruleForm.businessProfit"></el-input>
					</el-form-item>
					<el-form-item label="净利润(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入净利润(元)	" v-model="ruleForm.netProfit"></el-input>
					</el-form-item>
					<el-form-item label="经营活动净现金流(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入经营活动净现金流(元)	" v-model="ruleForm.cashFlowManagement"></el-input>
					</el-form-item>
					<el-form-item label="投资活动净现金流(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入投资活动净现金流(元)	" v-model="ruleForm.cashFlowInvestment"></el-input>
					</el-form-item>
					<el-form-item label="筹资活动净现金流(元):">
						<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入筹资活动净现金流(元)	" v-model="ruleForm.cashFlowFinancing"></el-input>
					</el-form-item>

				</el-form>
				<el-form :model="ruleForm" status-icon class="demo-ruleForm" label-position="rigth" label-width="170px"
					:class="{ read: subjectState == '查看' }" v-else>
					<el-form-item label="财务日期:">
						{{ ruleForm.financeDate.slice(0, 7) }}
					</el-form-item>
					<el-form-item label="货币资金(元):">
						{{ ruleForm.monetaryCapital }}
					</el-form-item>
					<el-form-item label="应收票据(元):">
						{{ ruleForm.receivableNote }}
					</el-form-item>
					<el-form-item label="应收账款(元):">
						{{ ruleForm.receivableAccount }}
					</el-form-item>
					<el-form-item label="存货(元):">
						{{ ruleForm.stockAmount }}
					</el-form-item>
					<el-form-item label="其他应收款(元):">
						{{ ruleForm.receivableOther }}
					</el-form-item>
					<el-form-item label="流动资产(元):">
						{{ ruleForm.assetsCurrent }}
					</el-form-item>
					<el-form-item label="无形资产(元):">
						{{ ruleForm.assetsIntangible }}
					</el-form-item>
					<el-form-item label="固定资产(元):">
						{{ ruleForm.assetsFixed }}
					</el-form-item>
					<el-form-item label="总资产(元):">
						{{ ruleForm.assetsTotal }}
					</el-form-item>
					<el-form-item label="短期借款(元):">
						{{ ruleForm.shortLoan }}
					</el-form-item>
					<el-form-item label="应付票据(元):">
						{{ ruleForm.payableNote }}
					</el-form-item>
					<el-form-item label="应付账款(元):">
						{{ ruleForm.payableAccount }}
					</el-form-item>
					<el-form-item label="其他应付款(元):">
						{{ ruleForm.payableOther }}
					</el-form-item>
					<el-form-item label="流动负债(元):">
						{{ ruleForm.payableCurrent }}
					</el-form-item>
					<el-form-item label="长期借款(元):">
						{{ ruleForm.longLoan }}
					</el-form-item>
					<el-form-item label="总负债(元):">
						{{ ruleForm.payableTotal }}
					</el-form-item>
					<el-form-item label="实收资本(元):">
						{{ ruleForm.capitalPaid }}
					</el-form-item>
					<el-form-item label="资本公积(元):">
						{{ ruleForm.capitalReserve }}
					</el-form-item>
					<el-form-item label="未分配利润(元):">
						{{ ruleForm.undistributedProfit }}
					</el-form-item>
					<el-form-item label="所有者权益(元):">
						{{ ruleForm.ownerEquity }}
					</el-form-item>
					<el-form-item label="主营业务收入(元):">
						{{ ruleForm.businessIncome }}
					</el-form-item>
					<el-form-item label="主营业务成本(元):">
						{{ ruleForm.businessCost }}
					</el-form-item>
					<el-form-item label="营业利润(元):">
						{{ ruleForm.businessProfit }}
					</el-form-item>
					<el-form-item label="净利润(元):">
						{{ ruleForm.netProfit }}
					</el-form-item>
					<el-form-item label="经营活动净现金流(元):">
						{{ ruleForm.cashFlowManagement }}
					</el-form-item>
					<el-form-item label="投资活动净现金流(元):">
						{{ ruleForm.cashFlowInvestment }}
					</el-form-item>
					<el-form-item label="筹资活动净现金流(元):">
						{{ ruleForm.cashFlowFinancing }}
					</el-form-item>

				</el-form>
				<div class="demo-drawer__footer">
					<el-button v-if="subjectState != '查看'" @click="postFmFinanceSubject" type="primary">保存</el-button>

					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import moment from 'moment';

import { fmFinanceSubjectAdd, fmFinanceSubjectUpdate } from '@/api/index.js';
export default {
	name: 'subjectsDrawer',
	props: {
		subjectsVisible: {
			type: Boolean,
			required: true,
		},
		parentRuleForm: {
			type: Object,
			required: true,
		},
		subjectData: {
			type: Object,
		},
		subjectState: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			ruleForm: {
				financeDate: '',
				monetaryCapital: '',
				receivableAccount: '',
				receivableNote: '',
				stockAmount: '',
				receivableOther: '',
				assetsCurrent: '',
				assetsIntangible: '',
				assetsFixed: '',
				assetsTotal: '',
				shortLoan: '',
				payableNote: '',
				payableAccount: '',
				payableOther: '',
				payableCurrent: '',
				longLoan: '',
				payableTotal: '',
				capitalPaid: '',
				capitalReserve: '',
				undistributedProfit: '',
				ownerEquity: '',
				businessIncome: '',
				businessCost: '',
				businessProfit: '',
				netProfit: '',
				cashFlowManagement: '',
				cashFlowInvestment: '',
				cashFlowFinancing: '',
				taxesIncome: '',
				taxesProfit: '',
				taxesTotal: '',
				taxAdded: '',
				taxIncome: '',
				cooperationPresidentId: '',
				clientName: '',
			},
			fileList: [],
		};
	},
	methods: {
		postFmFinanceSubject() {
			this.$refs['subRuleForm'].validate(async (valid) => {
				if (valid) {
					let res;
					this.ruleForm.financeDate = moment(
						this.ruleForm.financeDate
					)
						.endOf('month')
						.format('YYYY-MM-DD');
					this.ruleForm.clientName = this.parentRuleForm.clientName;
					this.ruleForm.cooperationPresidentId =
						this.$store.state.userInfo.userVo.cooperationPresidentId;
					if (this.subjectState == '新增') {
						res = await fmFinanceSubjectAdd(this.ruleForm);
					} else if (this.subjectState == '编辑') {
						res = await fmFinanceSubjectUpdate(this.ruleForm);
					}
					if (res.returncode == 0) {
						this.$confirm('提交成功', '提示', {
							confirmButtonText: '确定',
							type: 'success',
							showCancelButton: false,
						});
						this.$emit('closeVisible');
					}
				}
			});
		},
		open() {
			if (this.subjectState == '查看') {
				this.ruleForm = JSON.parse(JSON.stringify(this.subjectData));
			} else if (this.subjectState == '编辑') {
				this.ruleForm = JSON.parse(JSON.stringify(this.subjectData));
			}
		},
	},
	created() { },

	computed: {
		drawer_: {
			get() {
				return this.subjectsVisible;
			},
			set(v) {
				this.$emit('update:subjectsVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .demo-ruleForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

::v-deep .demo-ruleForm>div {
	width: 48%;
}

::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;

	.el-button {
		width: 15%;
	}
}

::v-deep .read .el-form-item {
	margin-bottom: 0;
}
</style>
